
// import ExpansionPanelService from '@/components/profile/ExpansionPanel.vue';
// import { colorStyle } from '@/components/profile/config';
import ApiService from '@/services/api-service.js';
import VideoDialog from '@/components/dialog/VideoDialog';
import moment from 'moment';

export default {
  name: 'PopUp',
  components: {
    VideoDialog,
    VueJsonToCsv: () => {
      if (process.client) {
        return import('vue-json-to-csv');
      }
    },
    // ExpansionPanelService,
    NoVideoDialog: () => {
      if (process.client) {
        return import('@/components/dialog/NoVideoDialog');
      }
    },
  },
  filters: {
    formatDate(date) {
      return moment(date).format('YYYY-MM-DD HH:mm:ss');
    },
  },
  props: {
    query: {
      type: Object,
      default: () => {},
    },

    currentPoint: {
      type: Object,
      default: () => {},
    },

    gradeDialog: Boolean,
    // dangerousVideoItem: Array,

    // // For filtering options
    // filterLabels: {
    //   default: () => [],
    //   type: Array,
    // },
  },
  data() {
    return {
      noVideoDialog: false,
      // DATATABLE
      dataTableOptions: {},
      totalItems: 0,
      dangerousVideoItem: [],
      grade: '',
      search: '',
      downloadList: [],
      warnword: '',
      // VIDEO
      videoDialog: false,
      media: '',
      isBSD: false,

      // IMAGE (Google maps)
      imgDisplay: false,
      lat: 0,
      lng: 0,
      location: '',
      src: 'https://maps.googleapis.com/maps/api/staticmap?center=',
      marker: '&markers=',
      params:
        '&zoom=16&size=660x460&key=AIzaSyDnCqlHgVv_C23CFiIYhS20l3qfP4BIxUk',
      overviewChartType: 'polarArea',
      // colorStyle,
      // dialog: false,

      // // Filter models
      // videoFilterValue: false,
      profilePopupWatchedVideo: {},

      // appeal
      appealDialog: false,
      appealItem: null,
      snackbar: false,
      reMsg: '',
      reason: null,
      other: '',
    };
  },
  computed: {
    cReason() {
      return [
        { text: this.$t('AppealReason-1'), value: this.$t('AppealReason-1') },
        { text: this.$t('AppealReason-2'), value: this.$t('AppealReason-2') },
        { text: this.$t('AppealReason-3'), value: this.$t('AppealReason-3') },
        { text: this.$t('AppealReason-4'), value: this.$t('AppealReason-4') },
        { text: this.$t('AppealReason-5'), value: 5 },
      ];
    },
    cSubmitAppeal: {
      get() {
        return this.$store.state.app.submitAppeal;
      },
    },
    // Expansion panel headers
    // expansionPanelTitle() {
    //   return [this.$t('DangerousDrivingBehaviour')];
    // },
    dialog: {
      get() {
        return this.gradeDialog;
      },
      set(val) {
        this.$emit('close', val);
      },
    },
    dangerousVideoheaders() {
      const hdrs = [
        // {
        //   text: this.$t('video'),
        //   value: 'video',
        //   // Filter empty videos when checkBox is ticked
        //   // variable value returns the data of that particular row in video header
        //   // return false -> don't display that row
        //   // Check Vuetify data table , "Custom Filtering"
        //   filter: (value) => {
        //     if (this.videoFilterValue) {
        //       if (value === null) {
        //         return false;
        //       }
        //       return true;
        //     } else {
        //       return true;
        //     }
        //   },
        // },
        { text: this.$t('Video'), value: 'video' },
        { text: this.$t('Location'), value: 'location' },
      ];
      if (this.cSubmitAppeal) {
        hdrs.push({ text: this.$t('Appeal'), value: 'appeal' });
      }
      hdrs.push(
        ...[
          // { text: this.$t('Latitude'), value: 'lat' },
          // { text: this.$t('Longitude'), value: 'lng' },
          // { text: this.$t('warningType'), value: 'warningType' },
          { text: this.$t('startTime'), value: 'startTime' },
          { text: this.$t('vehicle'), value: 'vehicle' },
          { text: this.$t('vehicleDept'), value: 'vehicleDept' },
          { text: this.$t('driver'), value: 'driver' },
          { text: this.$t('driverDept'), value: 'driverDept' },
          { text: this.$t('RateOfChange'), value: 'rateOfChange' },
          { text: this.$t('duration'), value: 'duration' },
          { text: this.$t('startSpeed'), value: 'startSpeed' },
          { text: this.$t('endSpeed'), value: 'endSpeed' },
          { text: this.$t('topSpeed'), value: 'topSpeed' },
          { text: this.$t('hw'), value: 'hw' },
          { text: this.$t('minHW'), value: 'minHW' },
          { text: this.$t('driverAction'), value: 'driverAction' },
          // { text: this.$t('DriverActionL'), value: 'driverActionl' },
          // { text: this.$t('DriverActionR'), value: 'driverActionr' },
          // { text: this.$t('DriverActionB'), value: 'driverActionb' },
          { text: this.$t('address'), value: 'address', width: '300px' },
        ]
      );
      if (this.IsSPW(this.currentPoint.rawLabel)) {
        hdrs.splice(12, 0, {
          text: this.$t('speedLimit'),
          value: 'speedLimit',
        });
      }
      return hdrs;
    },
    userName: {
      get() {
        return this.$store.state.app.userName;
      },
    },
  },
  watch: {
    '$store.state.app.locale'(val) {
      this.$i18n.locale = val;
    },
    gradeDialog(val) {
      this.dialog = JSON.parse(val);

      // * to check size > 0 to enforce not call on first time mount, but call in subsequent open dialog
      if (val && this.dangerousVideoItem.length > 0) {
        this.dataTableOptions.page = 1;
        this.dataTableOptions.sortBy = [];
        this.dangerousVideoItem = [];
        // ! since setting dataTableOptions above, will cause reactive trigger, so no need call below
        // this.getProfileWarningList(this.currentPoint.rawLabel);
      }
    },
    // videoFilterValue() {
    //   // console.log(this.videoFilterValue)
    // },
    dataTableOptions: {
      handler() {
        console.log('dataTableOptions handler');
        this.getProfileWarningList(this.currentPoint.rawLabel);
      },
      deep: true,
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    currentPoint(val) {
      // * to call api for data for csv
      this.downloadList = [];
      this.getDataForCsv(val.rawLabel);
    },
  },
  mounted() {
    if ('profilePopupWatchedVideo' in localStorage)
      this.profilePopupWatchedVideo = JSON.parse(
        localStorage.profilePopupWatchedVideo
      );
  },
  methods: {
    // ! appeal
    setAppeal() {
      let setReason = '';
      if (this.reason === 5) {
        setReason = this.other;
      } else {
        setReason = this.reason;
      }
      if (setReason === '') {
        return;
      }
      const rawData = {
        AppealedBy: this.$store.state.app.userName,
        AppealReason: setReason,
      };
      console.log(this.appealItem);
      ApiService.applyAppeal(
        this.$axios,
        this.appealItem.id.split('-')[0],
        rawData
      ).then((response) => {
        if (response) {
          if (response.success) {
            this.snackbar = true;
            this.reMsg = this.$t('CreateAppealSuccess');
            setTimeout(() => {
              this.$emit('reload', '');
            }, 3000);
          } else {
            this.reMsg = this.$t('CreateAppealError');
          }
          console.log(response);
        }
      });
      this.appealDialog = false;
    },
    getDataForCsv(label) {
      const newQuery = {
        TargetType: this.query.TargetType,
        TargetId: this.query.TargetId,
        Date: this.query.Date,
        ProfileType: this.query.ProfileType,
        OutputType: this.query.OutputType,
        Distance: this.query.Distance,
        // IsDanger: true,
        WarningType: label,
      };
      let data = [];
      ApiService.getProfileWarningList(this.$axios, newQuery).then(
        (response) => {
          data = response.items.map(this.createObj).map((item) => {
            const o = {};
            o[this.$t('Video')] = item.video || '';
            o[this.$t('Latitude')] = item.lat;
            o[this.$t('Longitude')] = item.lng;
            o[this.$t('startTime')] = item.startTime;
            o[this.$t('vehicle')] = item.vehicle;
            o[this.$t('vehicleDept')] = item.vehicleDept;
            o[this.$t('driver')] = item.driver || '';
            o[this.$t('driverDept')] = item.driverDept || '';
            o[this.$t('RateOfChange')] = item.rateOfChange || '';
            o[this.$t('duration')] = item.duration != null ? item.duration : '';
            o[this.$t('startSpeed')] =
              item.startSpeed != null ? item.startSpeed : '';
            o[this.$t('endSpeed')] = item.endSpeed != null ? item.endSpeed : '';
            o[this.$t('topSpeed')] = item.topSpeed != null ? item.topSpeed : '';

            if (this.IsSPW(this.currentPoint.rawLabel)) {
              o[this.$t('speedLimit')] = item.speedLimit ?? '';
            }

            o[this.$t('hw')] = item.hw != null ? item.hw : '';
            o[this.$t('minHW')] = item.minHW != null ? item.minHW : '';
            o[this.$t('driverAction')] = this.setDriverAction(item);
            o[this.$t('address')] =
              (this.$store.state.app.locale === 'en'
                ? item.addressEn
                : item.addressCh) ?? '';
            return o;
          });
          // Only if data is empty.
          if (data.length <= 0) data = [{ empty: '' }];

          this.downloadList = data;
        }
      );
    },
    refresh(val) {
      this.downloadList = [];
      this.getDataForCsv(val.rawLabel);
      this.dangerousVideoItem = [];
      this.getProfileWarningList(val);
    },
    playVideo(startTime, value, isBSD) {
      if (this.olderThanNDays(startTime, 60)) {
        this.noVideoDialog = true;
        return;
      }
      this.media = value;
      this.videoDialog = true;
      this.isBSD = isBSD;

      this.profilePopupWatchedVideo[`${this.userName}-${value}`] = true;
      localStorage.setItem(
        'profilePopupWatchedVideo',
        JSON.stringify(this.profilePopupWatchedVideo)
      );
    },
    source(item) {
      // console.log(item);
      // Reset src path and center string
      this.src = 'https://maps.googleapis.com/maps/api/staticmap?center=';
      this.location = '';
      this.marker = '&markers=';

      // Pop Up Window
      this.imgDisplay = true;

      // Locate Map (Static Google Map API)
      if (item.lat && item.lng !== null) {
        this.lat = item.lat;
        this.lng = item.lng;
        this.location = this.location.concat(this.lat, ',', this.lng);
        this.marker = this.marker.concat(this.location);
        this.src = this.src.concat(this.location, this.marker, this.params);
      }
    },
    getImage(itemVal) {
      if (itemVal === false) return false;
      else return true;
    },
    // getProfilePoint(currentPoint) {
    //   console.log(currentPoint);
    //   this.gradeDialog = true;
    //   this.dangerousVideoItem = [];
    //   this.warnword = currentPoint.label;
    //   this.getProfileWarningList(currentPoint.rawLabel);
    // },
    getProfileWarningList(label) {
      const newQuery = {
        TargetType: this.query.TargetType,
        TargetId: this.query.TargetId,
        Date: this.query.Date,
        ProfileType: this.query.ProfileType,
        OutputType: this.query.OutputType,
        Distance: this.query.Distance,
        // IsDanger: true,
        WarningType: label,
        Page: this.dataTableOptions.page,
        PageSize: this.dataTableOptions.itemsPerPage,
        SortBy: this.dataTableOptions.sortBy[0],
        IsSortAscending: !this.dataTableOptions.sortDesc[0],
      };
      // console.log(newQuery);

      this.wrapShowloading(
        ApiService.getProfileWarningList(this.$axios, newQuery)
          .then((response) => {
            /*
            response.items.forEach((p) => {
              this.dangerousVideoItem.push(this.createObj(p));
            });
            */
            this.dangerousVideoItem = response.items.map(this.createObj);
            this.totalItems = response.totalItems;
          })
          .catch((error) => {
            console.log(
              'ERROR: Profile Warning List ' + JSON.stringify(error.response)
            );
          })
      );
    },
    createObj(Obj) {
      return {
        video: Obj.mediaFullId,
        lat: Obj.lat,
        lng: Obj.lng,
        warningType: Obj.warningTypeText,
        startTime: Obj.startTime,
        vehicle: Obj.vehiclePlate,
        vehicleDept: Obj.vehicleDepartment,
        driver: Obj.driverName,
        driverDept: Obj.driverDepartment,
        speedLimit: Obj.speedLimit,
        rateOfChange: Obj.rateOfChange,
        // rateOfChange: this.getRateOfChange(
        //   Obj.startSpeed,
        //   Obj.endSpeed,
        //   Obj.duration
        // ).toFixed(3),
        duration: Obj.duration,
        startSpeed: Obj.startSpeed,
        endSpeed: Obj.endSpeed,
        topSpeed: Obj.topSpeed,
        hw: Obj.hw,
        minHW: Obj.minHW,
        driverActionl: Obj.leftSignalOn,
        driverActionr: Obj.rightSignalOn,
        driverActionb: Obj.brakeOn,
        isBSD: Obj.isBSD,
        addressEn: Obj.addressEn,
        addressCh: Obj.addressCh,
        id: Obj.id,
      };
    },
    getRateOfChange(startSpeed, endSpeed, duration) {
      return (startSpeed - endSpeed) / duration;
    },
    setDriverAction(item) {
      let driverAction = '';
      // if (item.brakeOn) driverAction += 'B';
      if (item.driverActionb) driverAction += 'B';
      // if (item.leftSignalOn) driverAction += 'L';
      if (item.driverActionl) driverAction += 'L';
      // if (item.rightSignalOn) driverAction += 'R';
      if (item.driverActionr) driverAction += 'R';
      return driverAction;
    },
  },
};
